import { render, staticRenderFns } from "./StakeSelectorItem.vue?vue&type=template&id=76162caf&scoped=true&"
import script from "./StakeSelectorItem.vue?vue&type=script&lang=js&"
export * from "./StakeSelectorItem.vue?vue&type=script&lang=js&"
import style0 from "./StakeSelectorItem.vue?vue&type=style&index=0&id=76162caf&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76162caf",
  null
  
)

export default component.exports