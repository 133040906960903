<template>
  <b-navbar-nav>

    <router-link :to="{ name: 'plaza' }" exact class="nav-link"
    v-if="!featureFlagStakeOnly">
      <li class="nav-item nav-top-links">
        <span class="gtag-link-others" tagname="plaza_screen">YOU CHARACTERS</span>
      </li>
    </router-link>

    <router-link :to="{ name: 'blacksmith' }" exact class="nav-link"   v-if="!featureFlagStakeOnly">
      <li class="nav-item nav-top-links" >
        <span class="gtag-link-others" tagname="blacksmith_screen">WEAPONS</span>
      </li>
    </router-link>

    <router-link :to="{ name: 'combat' }" exact class="nav-link"  v-if="!featureFlagStakeOnly">
      <li class="nav-item nav-top-links colori" >
        <span class="gtag-link-others combat-color" tagname="combat_screen">COMBAT</span>
      </li>
    </router-link>

    <a href="https://bscscan.com/token/0x2CCCBd4e8B92905F892a3F69999117889ABC41F2"  class="nav-item nav-top-links bla" target="_blank">
      BK TOKEN
    </a>

    <router-link :to="{ name: 'raid' }" exact class="nav-link"  v-if="!featureFlagStakeOnly && featureFlagRaid">
      <li class="nav-item nav-top-links">
        <span class="gtag-link-others" tagname="raid_screen">Raid</span>
      </li>
    </router-link>

  </b-navbar-nav>
</template>

<script>
import { market as featureFlagMarket, portal as featureFlagPortal } from '../feature-flags';

export default {
  inject: ['featureFlagStakeOnly', 'featureFlagRaid'],

  computed: {
    featureFlagMarket() {
      return featureFlagMarket;
    },
    featureFlagPortal() {
      return featureFlagPortal;
    }
  },
};
</script>

<style scoped>
a {
  font-weight: bold;
  font-size: 1em;
  padding: 0px 6px 0px 6px;
  padding-bottom: 6px !important;
}

.bla {
  padding: 0px 5px 0px 5px !important;
  padding-bottom: 9px !important;


}

.nav-top-links > span {
  color : #d1be00;
  font-size: 1em;
  padding: 0px 6px 0px 6px;
}

.colori > span {
  color : #c90000 !important;
}
</style>
