<template>
  <div class="body main-font">
    <div class="blank-slate">
    
      <div>
        BUY BK TOKEN ON <a href="https://pancakeswap.finance/swap?outputCurrency=0x2CCCBd4e8B92905F892a3F69999117889ABC41F2" target="_blank"><strong>PANCAKESWAP</strong></a>.
      </div>
      <br />
      <div class="blank-slate" v-if="userAccount === null && has5SkillBalance">
        
        <br />
        <a href="https://pancakeswap.finance/swap?outputCurrency=0x2CCCBd4e8B92905F892a3F69999117889ABC41F2" target="_blank">
         <big-button class="button" v-if="userAccount === null" mainText="GO TO PANCAKESWAP" />
        </a>
       
      </div>

    </div>
  </div>
</template>

<script>
import BigButton from '../components/BigButton.vue';
import * as waxjs from '@waxio/waxjs/dist';
import { mapGetters, mapState } from 'vuex';
const wax = new waxjs.WaxJS('https://wax.greymass.com', null, null, false);
import { toBN, fromWeiEther } from '../utils/common';

export default {
  data() {
    return {
      userAccount: null,
      publicKey: null,
      WAXAmount: null,
      regex: /^\d+\.\d{8}$/,
      transactionResults: null,
      waitingResults: false,
      waxBalance: 0,
      isValid: null,
    };
  },
  computed: {
    ...mapState(['defaultAccount', 'skillBalance', 'inGameOnlyFunds', 'skillRewards']),
    ...mapGetters(['getExchangeUrl', 'hasStakedBalance']),

    has5SkillBalance() {
      return toBN(fromWeiEther(this.skillBalance)).plus(toBN(fromWeiEther(this.skillRewards))).gte(5);
    },
  },

  methods: {
    async connectWaxWallet() {},
    //normal login. Triggers a popup for non-whitelisted dapps
    async waxLogin() {
      this.userAccount = await wax.login();
      this.publicKey = wax.pubKeys;
      this.getWaxBalance();
    },

    async sign() {
      if (!wax.api) {
        return document.getElementById('response').append('* Login first *');
      }
      try {
        this.waitingResults = true;
        const results = await wax.api.transact(
          {
            actions: [
              {
                account: 'eosio.token',
                name: 'transfer',
                authorization: [
                  {
                    actor: wax.userAccount,
                    permission: 'active',
                  },
                ],
                data: {
                  from: wax.userAccount, //user's BSC Address
                  to: process.env.VUE_APP_WAX_BRIDGE_WAX_WALLET_ADDRESS, //CB Wallet Address
                  quantity: toBN(this.WAXAmount).toFixed(8) + ' WAX', //WAX *needs* to be here.
                  memo: this.defaultAccount,
                },
              },
            ],
          },
          {
            blocksBehind: 3,
            expireSeconds: 30,
          },
        );
        this.transactionResults = results;
        this.waitingResults = false;
        this.error = null;
      } catch (e) {
        console.error(e);
        this.error = e.message;
      }
    },

    async change(e) {
      const number = e.target.value;
      this.isNumberValid(number);
    },

    async isNumberValid(inputNumber) {
      this.isValid = this.regex.test(inputNumber);
    },

    async getWaxBalance() {
      this.waxBalance = await wax.api.rpc.get_currency_balance('eosio.token', this.userAccount, 'WAX');
    },
  },
  components: {
    BigButton,
  },
};
</script>

<style scoped>
.button-row {
  margin-top: 1em;
  display: flex;
}
.sub-container {
  flex: 1;
}
</style>
